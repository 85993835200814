<template>
  <div v-if="!isMobile" class="w-80 flex flex-col items-center justify-center">
    <div
      class="block-uc-id-qr-code flex flex-col justify-center items-center"
      :style="{ width: qrcodeSize + 60 + 'px', height: qrcodeSize + 60 + 'px' }"
    >
      <div class="flex" @click="reactivatePasswordless">
        <div v-if="!isLoading && qrCode">
          <div class="flex justify-center p-2 relative">
            <qrcode-vue
              class="p-2 flex items-center justify-center bg-white rounded"
              :class="{ 'opacity-50': disabled }"
              :value="qrCode"
              :size="qrcodeSize"
              level="L"
            ></qrcode-vue>
          </div>
          <div class="flex flex-row content-center justify-center" :class="{ hidden: !showCountdown }">
            <div
              class="text-xs text-center"
              :class="{
                'text-gray-100': dark,
                'text-gray-600': !dark,
              }"
            >
              Expira em <span class="w-5 inline-block text-center">{{ countdown }}</span> segundos.
              <div v-if="debugmode" class="opacity-30 text-center">
                {{ authId != null ? authId.substring(0, 4) : '' }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="relative cursor-pointer">
          <qrcode-vue
            class="p-2 flex items-center justify-center bg-white opacity-50 rounded"
            :value="'Nothing here. Just a dummy qrcode.'"
            :size="qrcodeSize"
            level="L"
          ></qrcode-vue>
          <div
            v-if="disabled"
            class="rounded-full -ml-16 left-1/2 text-center text-sm font-semibold absolute top-1/2 text-gray-600 -mt-5 bg-gray-50 shadow-lg px-8 py-3"
          >
            Gerar novo
          </div>
          <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
        </div>
      </div>
      <b-modal :active.sync="isOpened" :width="640" scroll="keep" full-screen :can-cancel="false" class="h-full">
        <div class="p-5 flex flex-col h-full justify-center">
          <fw-heading class="text-center" size="h1">Passwordless Login</fw-heading>
          <div class="my-4 flex-1 justify-center items-center flex">
            <b-loading :active.sync="isLoading"></b-loading>
            <div
              v-if="!isLoading && qrCode"
              :class="{
                'p-5 bg-white rounded': dark,
              }"
            >
              <div class="flex justify-center p-2">
                <qrcode-vue
                  class="p-2 flex items-center justify-center bg-white rounded"
                  :value="qrCode"
                  :size="250"
                  level="L"
                ></qrcode-vue>
              </div>
            </div>
          </div>
          <fw-button type="black" :expanded="true" @click.native="closeModal">Fechar</fw-button>
        </div>
      </b-modal>
    </div>
    <div v-if="showBranding" class="text-center mx-auto w-64">
      <fw-heading class="flex items-center justify-center">
        Log in with <fw-icon-uc-one class="w-16 h-16 p-2"></fw-icon-uc-one>
      </fw-heading>
      <div class="text-sm">
        Scan this with the code
        <strong
          :class="{
            'text-gray-100': dark,
            'text-gray-600': !dark,
          }"
          >UC One mobile app</strong
        >
        to log in instantly.
      </div>
    </div>
    <div v-if="showBranding && links" class="flex items-center justify-center gap-1 w-64 mx-auto mt-2">
      <div>
        <a :href="`https://apps.apple.com/${appleStoreLanguage}/app/uc-one/id1611415635`"
          ><img src="https://one.uc.pt/images/appstore-badge.png"
        /></a>
      </div>
      <div>
        <a href="https://play.google.com/store/apps/details?id=pt.uc.one"
          ><img src="https://one.uc.pt/images/google-play-badge.png"
        /></a>
      </div>
    </div>
    <div v-if="showBranding && !links" class="flex items-center justify-center gap-1 w-64 mx-auto mt-2">
      <div>
        <img src="https://one.uc.pt/images/appstore-badge.png" />
      </div>
      <div>
        <img src="https://one.uc.pt/images/google-play-badge.png" />
      </div>
    </div>
  </div>
</template>

<script>
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import QrcodeVue from 'qrcode.vue'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  components: {
    QrcodeVue,
  },

  props: {
    openQRcode: {
      type: Boolean,
    },
    qrcodeSize: {
      type: Number,
      default: 200,
    },
    callbackApp: {
      type: String,
      default: null,
    },
    secondsToExpire: {
      type: Number,
      default: 180,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    showBranding: {
      type: Boolean,
      default: true,
    },
    showCountdown: {
      type: Boolean,
      default: true,
    },
    hideMobile: {
      type: Boolean,
      default: true,
    },
    qrCodeMetadata: {
      type: Object,
      default: () => ({}),
    },
    restartAfterExpired: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isLoading: true,
      isOpened: this.openQRcode || false,
      qrCode: null,
      authId: null,
      // nonce: null,
      // created: null,
      keyPair: null,
      // signature: null,
      qrcodeAutoTimer: null,
      countdown: this.secondsToExpire, // 3 minutes (keep lower than passwordlessExpirationMilliseconds)
      qrcodeSecondsLimit: this.secondsToExpire, // 3 minutes  (keep lower than passwordlessExpirationMilliseconds)
      passwordlessExpirationMilliseconds: 1000 * 60 * 5, // 5 minutes
      authStartedDate: null,
      disabled: false,
    }
  },

  computed: {
    debugmode() {
      return Boolean(localStorage.getItem('debug'))
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    isMobile() {
      return this.hideMobile && window.innerWidth < 640
    },

    appleStoreLanguage() {
      return this.$i18n.locale == 'en' ? 'us' : 'pt'
    },

    // public_device() {
    //   return false
    // },
  },

  watch: {
    openQRcode(value) {
      this.isOpened = value
    },
  },

  mounted() {
    if (!this.isMobile && !this.qrcodeAutoTimer) this.generateKey()
  },

  beforeDestroy() {
    console.log('beforeDestroy')
    this.disablePasswordless()
  },

  methods: {
    reactivatePasswordless() {
      this.isLoading = true
      console.log('REACTIVATE')
      //if (!this.disabled) {
      this.unsubscribeConnection()
      this.generateKey()
      //}
    },

    resetData() {
      this.isLoading = false
      this.isOpened = this.openQRcode || false
      this.qrCode = null
      this.authId = null
      // this.nonce = null
      // this.created = null
      this.keyPair = null
      this.countdown = this.qrcodeSecondsLimit
      // this.signature = null
      this.disabled = true
      if (this.qrcodeAutoTimer) {
        clearInterval(this.qrcodeAutoTimer)
        this.qrcodeAutoTimer = null
      }
      //this.authStartedDate = null
    },

    /**
     * Converting array buffer in hexadecimal string
     */
    bufToHex(buf) {
      const hashArray = Array.from(new Uint8Array(buf)) // convert buffer to byte array
      const msgHash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
      // console.log('msgHash: ', msgHash)
      return msgHash
    },

    /**
     * Export RSA public key in Json Web Key format
     */
    async exportCryptoKey() {
      const exported = await window.crypto.subtle.exportKey('jwk', this.keyPair.publicKey)
      // console.log('RSA JWK: ', exported)
      this.publicKey = exported

      //START LOGIN PROCESS AUTOMATICALLY
      this.getQRCode()
    },

    /**
     * Generate a sign/verify key and export it in Json Web Key format
     */
    // async generateKey() {
    //   let keyPair = await window.crypto.subtle.generateKey(
    //     {
    //       name: 'RSA-PSS',
    //       modulusLength: 2048,
    //       publicExponent: new Uint8Array([1, 0, 1]),
    //       hash: 'SHA-256',
    //     },
    //     true,
    //     ['sign', 'verify']
    //   )
    //   this.keyPair = keyPair

    //   this.exportCryptoKey()
    // },

    /**
     * Generate a encrypt/decrypt key and export it in Json Web Key format
     */
    async generateKey() {
      console.log('generateKey ===')
      let keyPair = await window.crypto.subtle.generateKey(
        {
          name: 'RSA-OAEP',
          modulusLength: 2048,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: 'SHA-256',
        },
        true,
        ['encrypt', 'decrypt']
      )
      this.keyPair = keyPair

      this.exportCryptoKey()
    },

    /**
     * Get the message,hash it and encode it in hexadecimal form.
     */
    // async getMessageEncoding() {
    //   let message = `${this.authId}-${this.nonce}-${this.created}`
    //   // console.log('Message: ', message)
    //   const encMsg = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
    //   const hashBuffer = await window.crypto.subtle.digest('SHA-256', encMsg) // hash the message
    //   return new TextEncoder().encode(this.bufToHex(hashBuffer))
    // },

    /**
     * Fetch the encoded message-to-sign, sign it and convert it to hexadecimal format.
     */
    // async signMessage() {
    //   const signature = await window.crypto.subtle.sign(
    //     {
    //       name: 'RSA-PSS',
    //       saltLength: 32,
    //     },
    //     this.keyPair.privateKey,
    //     await this.getMessageEncoding()
    //   )

    //   const msgHash = this.bufToHex(signature)
    //   // console.log('RSA signature: ', msgHash)
    //   this.signature = msgHash
    // },

    /**
     * Start passwordless login
     */
    async startLogin() {
      try {
        const res = await ServiceAuth.passwordlessStart(this.publicKey)
        console.log('passwordlessStart', res)
        this.authId = res.auth_id
        this.disabled = false
        this.authStartedDate = new Date()

        const data = {
          application: 'id',
          code: 'anonymous_auth',
          auth_id: this.authId,
        }

        const queue = this.$store.state.socket.queue
        if (queue && queue.length) {
          this.$store.state.socket.queue = queue.filter(
            item => item.application != 'id' && item.code != 'anonymous_auth'
          )
        }
        this.$store.commit('sendWSMessage', data)
      } catch (error) {
        console.error(error)
      }
    },

    /**
     * Fetch the encoded message-to-sign, subscribe to the connection and ask the backend to verify the signature against the stored one
     */
    // async verifyMessage() {
    //   const data = {
    //     application: 'id',
    //     code: 'passwordless_subscribe',
    //     sig: this.signature,
    //     auth_id: this.authId,
    //     nonce: this.nonce,
    //     created: this.created,
    //   }

    //   // console.log('Send sendWSMessage to verify: ', data)
    //   this.$store.commit('sendWSMessage', data)
    // },

    async decryptMessage(ciphertext) {
      try {
        const decryptedMsg = await window.crypto.subtle.decrypt(
          {
            name: 'RSA-OAEP',
          },
          this.keyPair.privateKey,
          Buffer.from(ciphertext, 'base64') // Convert base64 text to ArrayBuffer
        )
        // console.log('decryptedMsg: ', decryptedMsg)
        return decryptedMsg
      } catch (error) {
        console.error(error)
      }
    },

    /*
      Convert an ArrayBuffer into a string
      from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
    */
    ab2str(buf) {
      return String.fromCharCode.apply(null, new Uint8Array(buf))
    },

    async parseMessages(message) {
      console.log('Received: ', message)
      if ('anonymous_auth' in message) {
        // WS Connection start
        for (let error of utils.errors(message.anonymous_auth).items) {
          if (error.key === 'ConnectionAuthAlreadyDefined') {
            console.warn('WS: ConnectionAuthAlreadyDefined, restart')
            this.disablePasswordless()
          }
        }
      }
      // if ('passwordless_subscribe' in message) {
      //   // Signature validation result from server
      //   for (let error of utils.errors(message.passwordless_subscribe).items) {
      //     if (error.key === 'Unauthorized') {
      //       console.warn('WS: Something went wrong verifying signatures or dates, going to restart process')
      //     }
      //   }
      //   const info = message.passwordless_subscribe[0]
      //   if (info.auth_id && info.valid) {
      //     /*this.qrCode = JSON.stringify({
      //       authId: this.authId,
      //       nonce: this.nonce,
      //       public_device: false,
      //       browser_created: this.created,
      //       browser_sig: this.signature,
      //     })*/
      //     this.qrCode = `auth|${this.authId}`
      //   } else {
      //     console.error('Not valid sig!', info)
      //   }
      // } else
      else if ('passwordless_auth' in message) {
        console.log('passwordless_auth RECIEVED!!!')
        const info = message.passwordless_auth[0]
        if (!info.token) {
          this.$buefy.dialog.alert({
            message: 'Login nao foi autorizado.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true,
          })
        } else {
          const decryptedInfo = await this.decryptMessage(info.token)
          if (!this.restartAfterExpired) {
            this.disablePasswordless()
          }

          setTimeout(async () => {
            this.$store.commit('setToken', this.ab2str(decryptedInfo))
            this.$store.commit('setUser', {})

            this.$emit('loggedin')

            if (this.redirect) {
              const redirected = await ServiceAuth.appAuth(this.callbackApp)
              if (!redirected) {
                let redirect = this.$route.query.redirect || '/'
                if (redirect.includes('logout')) redirect = '/'
                this.$router.push(redirect)
              }
            }
          }, 500)
        }
      }
    },

    closeModal() {
      console.log('closeModal')
      this.isOpened = false
      this.disablePasswordless()
    },

    disablePasswordless() {
      if (this.qrcodeAutoTimer) {
        clearInterval(this.qrcodeAutoTimer)
        this.qrcodeAutoTimer = null
      }
      this.unsubscribeConnection()
      this.resetData()
    },

    unsubscribeConnection() {
      console.log('unsubscribeConnection')
      this.$store.commit('unsubscribeWS', { name: 'passwordless_auth', code: 'id' })
      this.$socket.close()
    },

    /**
     * Generate new qrcode every 2 minutes
     */
    async getQRCode() {
      if (this.loggedUser) {
        this.$store.dispatch('resetToken')
      }
      await this.startLogin()

      this.$store.commit('subscribeWS', {
        code: 'id',
        name: 'passwordless_auth',
        callback: this.parseMessages,
      })

      this.generateQRCode()
      if (this.qrcodeAutoTimer == null) {
        this.qrcodeAutoTimer = setInterval(() => {
          // Check passwordless login validity
          if (new Date().getTime() - this.authStartedDate.getTime() > this.passwordlessExpirationMilliseconds) {
            console.warn('QRCode expired')
            this.disablePasswordless()
          } else {
            if (this.countdown <= 1) {
              // GENERATE NEW QR CODE
              // this.getQRCode()

              // Auto restart
              // Beware! This can cause more load to servers (ws)
              if (this.restartAfterExpired) {
                console.log('restartAfterExpired')
                this.resetData()
                this.reactivatePasswordless()
              } else {
                console.log('NOT restartAfterExpired')
                this.disablePasswordless()
              }
            } else {
              this.countdown--
              this.$emit('countdown', this.countdown)
            }
          }
        }, 1000)
      }
    },

    /**
     * Generate the qrcode for authentication
     */
    async generateQRCode() {
      this.isLoading = true
      this.countdown = this.qrcodeSecondsLimit
      try {
        // this.nonce = this.bufToHex(window.crypto.getRandomValues(new Uint32Array(32)))
        // this.created = dayjs.utc().format('YYYY-MM-DD HH:mm:ss')
        if (this.qrCodeMetadata != null) {
          let url = `${FwEnvConfig.appUrlOne}/auth/${this.authId}?`
          let keys = Object.keys(this.qrCodeMetadata)
          keys.forEach(key => {
            url += `${encodeURIComponent(key)}=${encodeURIComponent(this.qrCodeMetadata[key])}`
            if (keys.indexOf(key) < keys.length - 1) url += '&'
          })
          console.log('GENERATED QR CODE: ', url)
          this.qrCode = url + `${keys.length > 0 ? '&' : ''}time=${new Date().getTime().toString()}`
        } else {
          this.qrCode = `${FwEnvConfig.appUrlOne}/auth/${this.authId}` + '?time=' + new Date().getTime().toString()
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style>
.loading-overlay .loading-icon:after {
  border: 3px solid #039770;
  border-right-color: transparent;
  border-top-color: transparent;
}
</style>
